<template>
    <div class="invoice-detail content-wrapper">
        <rq-banner
            variant="warn"
            icon="fas fa-exclamation-triangle"
            message="Invoice Changed - Click Save to Update"
            :visible="hasChanges"
        />
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section :title="formTitle" headerSize="lg" borderless>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item" v-if="!isNewInvoice" v-rq-tooltip.hover :title="!canAddInvoice ? 'Current Invoice is read-only, to add new Invoices, select View Invoice List and select Add Invoice.' : ''">
                        <b-btn automation_id="btn_add_invoice" variant="theme" :disabled="!canAddInvoice" @click="onAddInvoice" v-if="localSecurity.PremiumsAccessible">Add Invoice</b-btn>
                    </li>
                    <li class="nav-item" v-if="!isNewInvoice">
                        <b-btn automation_id="btn_delete_invoice" variant="theme" :disabled="!canDeleteThisInvoice" @click="onDeleteInvoice">Delete Invoice</b-btn>
                    </li>
                    <li class="nav-item" v-if="isTitleOnly">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Manage</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item"  automation_id="btn_create_r_and_d" @click="onCreateReceiptsAndDisbursements" :disabled="readOnly || !allowCreateReceiptsAndDisbursements" v-rq-tooltip.hover.left :title="allowCreateReceiptsAndDisbursements ? '' : 'Access Restricted'">Create Receipts &amp; Disbursements</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item"  automation_id="btn_delete_r_and_d" @click="onDeleteReceiptsAndDisbursements" :disabled="readOnly || !allowDeleteReceiptsAndDisbursements" v-rq-tooltip.hover.left :title="allowDeleteReceiptsAndDisbursements ? '' : 'Access Restricted'">Delete Receipts &amp; Disbursements</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item" v-if="!isNewInvoice">
                        <rq-report-button
                            text="Print Invoice"
                            :reports="reportItems"
                        />
                    </li>
                </ul>
                <ul class="nav ms-auto">
                    <li class="nav-items">
                        <dx-select-box
                            v-if="showInvoiceDropDown"
                            :input-attr="{ automation_id: 'drp_invoice' }"
                            :element-attr="{ class: 'form-control form-control-sm' }"
                            value-expr="id"
                            display-expr="name"
                            :items="invoiceOptions"
                            :search-enabled="false"
                            v-model="selectedInvoiceId"
                            @valueChanged="onInvoiceDropdownChange"
                        />
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <button v-if="showInvoiceDropDown" automation_id="btn_view_order_invoices" type="button" class="btn btn-link btn-theme" @click="onViewInvoiceList">View Invoice List</button>
                <button v-if="localSecurity.PremiumsAccessible" automation_id="btn_view_premiums" type="button" class="btn btn-link btn-theme" @click="onShowPremiums">View Premiums</button>
                <button v-if="isTitleOnly" automation_id="btn_view_premiums" type="button" class="btn btn-link btn-theme" @click="onShowRecordingFees">View Recording Fees & Taxes</button>
            </template>
        </rq-page-section>
        <rq-tabs
            :tabs="tabItems"
            v-model="tabIndex"
            small>
            <template #invoiceDetails>
                <invoice-detail-section
                    ref="invoiceDetailSection"
                    :invoice-detail="invoiceDetail"
                    :original-data="originalData"
                    v-model:invoice-custom-data="customData"
                    v-model:original-invoice-custom-data="originalCustomData"
                    :loans="loans"
                    @fillInvoice="onFillInvoice"
                    @reorderInvoiceLines="onReorderInvoiceLines"
                />
            </template>
            <template #payments>
                <invoice-payment-section
                    ref="paymentSection"
                    :invoice-detail="invoiceDetail"
                />
            </template>
            <template #creditMemos>
                <credit-memo-section
                    ref="creditMemoSection"
                    :invoice-detail="invoiceDetail"
                />
            </template>
            <template #notes>
                <note-section
                    ref="noteSection"
                    :invoice-detail="invoiceDetail"
                />
            </template>
            <template #summary>
                <invoice-summary-section
                    ref="invoiceSummary"
                    :invoice-detail="invoiceDetail"
                    large-bottom-margin
                />
            </template>
        </rq-tabs>
    </div>
</template>

<script>
    import { ref, computed } from "vue";
    import { mapState, mapGetters } from "vuex";
    import { SET_PAGE_READ_ONLY } from "@/store/mutations";
    import { GlobalEventManager } from "@/app.events";
    import { UserScreenAccessLevel } from "@/shared/models/enums";
    import InvoiceDetailSection from "./components/InvoiceDetailSection";
    import InvoicePaymentSection from "./components/InvoicePaymentSection";
    import CreditMemoSection from "./components/CreditMemoSection";
    import InvoiceSummarySection from "./components/InvoiceSummarySection";
    import NoteSection from "./components/NoteSection";
    import PremiumsMain from "../settlement/components/premiums/PremiumsMain"
    import RecordingFeesAndTaxes from "../settlement/components/recording-fees-and-taxes/RecordingFeesAndTaxes.vue"
    import { InvoiceDetail, InvoiceLineModel, InvoicePaymentModel, CreditMemoModel, InvoiceFillType} from './models';
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";
    import { useRqTabAlerts } from "@/shared/composables/useRqTabAlerts";
    import { INVOICE_MUTATIONS } from '@/store/mutations';
    import { INVOICE_ACTIONS, ORDER_ACTIONS } from '@/store/actions';
    import { DateTimeHelper } from '@/shared/utilities';
    import { DateTime } from "luxon";

    const InvoiceEditing = {
        NewOnly: 0,
        UnpaidOnly: 1,
        Unrestricted: 2
    };

    const InvoiceDeletion = {
        CannotDelete: 0,
        UnpaidOnly: 1,
        Unrestricted: 2
    }

    export default {
        name: "InvoiceDetail",
        components:{
            InvoiceDetailSection,
            InvoicePaymentSection,
            CreditMemoSection,
            InvoiceSummarySection,
            NoteSection
        },
        props: {
            invoiceId: { type: Number, default: 0 }
        },
        setup() {
            const { getTabClass } = useRqTabAlerts();

            const detailsTabErrorCount = ref(0);
            const paymentsTabErrorCount = ref(0);
            const detailsTabClass = computed(() => getTabClass(detailsTabErrorCount.value));
            const paymentsTabClass = computed(() => getTabClass(paymentsTabErrorCount.value));

            return {
                detailsTabClass,
                detailsTabErrorCount,
                paymentsTabClass,
                paymentsTabErrorCount,
            };
        },
        data(){
            return {
                invoiceDetail: null,
                invoices: [],
                originalData: null,
                errorMessage: "",
                loans: [],
                selectedInvoiceId: null,
                globalHandlersRegistered: false,
                selectedSource: null,
                tabIndex: 0,
                componentRefs: [
                    "invoiceDetailSection",
                    "paymentSection",
                    "creditMemoSection",
                    "noteSection",
                    "invoiceSummary"
                ],
                customData: [],
                originalCustomData: [],
                customDataIsValid: true,
                hasEditPermission: true
            }
        },
        computed: {
            ...mapState({
                order: state => state.orders.order,
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary,
                readOnly: state => _.parseBool(state.isPageReadOnly),
                storedRateOrderInfo: state => state.rateEngine.rateOrderInfo,
                isOrderLocked: state => _.getBool(state, "orders.orderSummary.isLocked"),
                systemDefaults: state => state.system.systemDefaults,
                orderInvoices: state => state.orders.invoices
            }),
            ...mapGetters(["invoiceOptions"]),
            localSecurity(){
                 let result = this.securitySettings.findValues([
                    "AllowDepositDelete",
                    "AllowDeleteCheck",
                    "AllowChangePayeeNameOnInvoice",
                    "AllowChangeTaxRateOnInvoice",
                    "AllowMakeDefaultInvoiceLines",
                    "CanAddChecks",
                    "CanAddDeposits",
                    "InvoiceEditing",
                    "InvoiceDeletion",
                    "InvoicePostDateDays",
                    "InvoicePreDateDays",
                    "Invoices_ScreenAccess",
                    "Premiums"
                ]);
                result.PremiumsAccessible = this.securitySettings.hasAccess("Premiums");
                return result;
            },
            allowDeleteReceiptsAndDisbursements() { return this.localSecurity.AllowDepositDelete && this.localSecurity.AllowDeleteCheck && !this.orderSummary.receiptsOrDisbursementsPosted && !this.orderSummary.isEscrowLocked; },
            allowCreateReceiptsAndDisbursements(){ return this.localSecurity.CanAddChecks && this.localSecurity.CanAddDeposits && this.receiptsAndDisbursementsAvailability.canCreate && !this.orderSummary.isEscrowLocked;},
            tabItems() {
                return [
                    { automation_id: "btn_tab_invoiceDetails", name: "invoiceDetails", title: "Invoice Details", alertCount: this.detailsTabErrorCount.alertCount },
                    { automation_id: "btn_tab_payments", name: "payments", title: "Payments", alertCount: this.paymentsTabErrorCount.alertCount },
                    { automation_id: "btn_tab_creditMemos", name: "creditMemos", title: "Credit Memos" },
                    { automation_id: "btn_tab_notes", name: "notes", title: "Notes" },
                    { automation_id: "btn_tab_summary", name: "summary", title: "Summary" },
                ];
            },
            isNewInvoice(){ return this.invoiceDetail && this.invoiceDetail.invoiceID === 0; },
            isTitleOnly(){ return _.isEqual(this.order?.transactionTypeID, 3); },
            formTitle(){
                if(this.isNewInvoice) return "ADDING INVOICE";
                let message = this.invoiceDetail ? `INVOICE ID: ${this.invoiceDetail.invoiceID} - ${_.formatMoney(this.invoiceDetail.grandTotal)}` : "INVOICE ID:";
                // if(this.hasChanges)
                //     message = message + " * Invoice changed. Click Save to see an updated amount."
                return message;
            },
            scrollContainer() { return this.$refs.rqScrollContainer; },
            hasChanges(){
                const self = this;
                if(self.readOnly) return false;
                if(_.isNil(self.invoiceDetail)) return false;
                //check for changes
                let hasChanges = self.hasChildChanges;

                if(!hasChanges){
                    let changes = self.getAuditChanges(self.invoiceDetail, self.originalData);
                    let customDataChanges = _.differenceWith(self.customData, self.originalCustomData, _.isEqual);
                    if(changes.length > 0 || customDataChanges.length > 0)
                        hasChanges = true;
                }
                return hasChanges;
            },
            hasChildChanges(){
                const self = this;
                let hasChanges = false;
                if(self.invoiceDetail.deletedInvoiceLines.length > 0 ||
                    self.invoiceDetail.deletedCreditMemos.length > 0 ||
                    self.invoiceDetail.deletedInvoicePayments.length > 0)
                    hasChanges = true;
                if(self.invoiceDetail.invoiceLines.length !== self.originalData.invoiceLines.length ||
                    self.invoiceDetail.creditMemos.length !== self.originalData.creditMemos.length ||
                    self.invoiceDetail.invoicePayments.length !== self.originalData.invoicePayments.length)
                    hasChanges = true;

                return hasChanges;
            },
            showInvoiceDropDown() { return this.invoices.length > 1; },
            canAddInvoice() {
                if (this.isOrderLocked) return false;
                return  this.localSecurity.InvoiceEditing === InvoiceEditing.NewOnly ||
                        this.localSecurity.InvoiceEditing === InvoiceEditing.Unrestricted ||
                        this.localSecurity.Invoices_ScreenAccess === UserScreenAccessLevel.Full;
            },
            canDeleteInvoices() { return !this.readOnly && this.localSecurity.InvoiceDeletion !== InvoiceDeletion.CannotDelete; },
            canDeleteUnpaidInvoicesOnly() { return !this.readOnly && this.localSecurity.InvoiceDeletion === InvoiceDeletion.UnpaidOnly; },
            canDeleteThisInvoice(){ return this.canDeleteInvoices || (!this.hasPayments && this.canDeleteUnpaidInvoicesOnly); },
            hasPayments(){ return _.sumBy(this.invoiceDetail?.invoicePayments, p => { return p.payment; }) > 0; },
            reportItems() {
                const self = this;
                // if(!self.dataLoaded) return [];
                return [
                    {
                        text: "Invoice",
                        path: "System Reports\\File Specific\\Invoice",
                        disabled: false,
                        immediate: true,
                        automation_id: "prt_invoice",
                        name: "Invoice",
                        options: new ReportOptionsDto({
                            title: 'Invoice',
                            parameters: {
                                p_Regions:[],
                                p_Branches:[],
                                p_InvoiceID: self.selectedInvoiceId,
                                p_IsDetail: 0
                            },
                        })
                    },
                    {
                        text: "Invoice With Detail",
                        path: "System Reports\\File Specific\\Invoice",
                        disabled: false,
                        immediate: true,
                        automation_id: "prt_invoice_with_detail",
                        name: "Invoice Details",
                        options: new ReportOptionsDto({
                            title: 'Invoice Details',
                            parameters: {
                                p_Regions:[],
                                p_Branches:[],
                                p_InvoiceID: self.selectedInvoiceId,
                                p_IsDetail: 1
                            },
                        })
                    }
                ];
            },
            receiptsAndDisbursementsAvailability(){ return _.get(this, "orderSummary.receiptsAndDisbursementsAvailability"); },
        },
        watch: {
            invoiceId(newVal, oldVal) {
                if (_.isEqual(newVal, oldVal)) return;
                this.initInvoice();
            },
            tabIndex(newVal, oldVal) {

                //if the user navigates to the summary tab, call save to make sure it's up to date
                if(newVal === 4){
                    this.save();
                    return;
                }

                let gridComponentIndexes = [0,1,2];

                if(_.isEqual(newVal, oldVal) || !_.includes(gridComponentIndexes, newVal)) return;
                //updating dimensions in watcher since "@activate-tab" is fired prior to index changing
                this.$nextTick(() => {
                    _.invoke(this, `$refs.${this.componentRefs[newVal]}.updateDimensions`);
                });
            },
            orderInvoices: {
                handler(newValue) {
                    this.invoices = _.cloneDeep(newValue);
                },
                immediate: true
            },
        },
        created () {
            const self = this;
            self.tabRoutes = [
                { name: "invoice:details", hash: "#invoice-details", label: "Invoice Details" },
                { name: "invoice:payments", hash: "#invoice-payments", label: "Payments" },
                { name: "invoice:credit-memo", hash: "#credit-memo", label: "Credit Memos" },
                { name: "invoice:summary", hash: "#invoice-summary", label: "Summary" }
            ];
            self.selectedInvoiceId = self.invoiceId;
            self.initInvoice();
            GlobalEventManager.onSave(self, self.onSave);
            GlobalEventManager.onCancel(self, self.onCancel);
        },
        beforeUnmount () {
            GlobalEventManager.unregister(this);
        },
        methods:{
            canDeleteReceiptsAndDisbursements(){
                const self = this;
                let promise = self.$api.CheckWritingApi.canDeleteReceiptsAndDisbursements(self.orderId).then(result => {
                    if(result.success && result.errors.length === 0 && result.warnings.length === 0)
                        self.doDeleteReceiptsAndDisbursement();
                    else if(!result.success){
                        self.errorMessage = result.errors[0];
                    }
                    else{
                        self.confirmDeleteReceiptsAndDisbursements(result.warnings);
                    }
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error({ message: `Error deleting receipts and disbursements.` });
                });
                self.$rqBusy.wait(promise);
                return promise;
            },
            async initInvoice() {
                const self = this;
                if(self.receiptsAndDisbursementsAvailability.settlementStatementSelections.length === 1) {
                    self.selectedSource = self.receiptsAndDisbursementsAvailability.settlementStatementSelections[0];
                }
                if(self.invoiceId === 0)
                    return await self.initNewInvoice();
                return await self.fetchData();
            },
            canCombineFunds(settlementStatementSelection){
                const self = this;
                let cancel = function (args) {
                    settlementStatementSelection.combineFunds = false;
                    self.createReceiptsAndDisbursements(settlementStatementSelection);
                };
                let ok = function (args) {
                    self.errorMessage = "";
                    settlementStatementSelection.combineFunds = true;
                    self.createReceiptsAndDisbursements(settlementStatementSelection);
                };
                let promise = self.$api.CheckWritingApi.canCombineFunds(self.orderId, settlementStatementSelection).then(result => {
                    if(result){
                        self.$dialog
                            .confirm(`Combine Funds?`,
                                "Do you want to combine the buyer funds from secondary loan(s) to the first loan for a single deposit?",
                                ok, cancel, { cancelTitle: 'No', okTitle: 'Yes'}
                    );
                    }
                    else{
                        self.createReceiptsAndDisbursements(settlementStatementSelection);
                    }
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error({ message: `Error creating receipts and disbursements.` });
                });
                self.$rqBusy.wait(promise);
                return promise;
            },
            createReceiptsAndDisbursements(settlementStatementSelection){
                const self = this;
                let promise = self.$api.CheckWritingApi.doCreateReceiptsAndDisbursements(self.orderId, settlementStatementSelection).then(result => {
                    self.$toast.success({ message: `Successfully created receipts and disbursements.` });
                    self.$store.dispatch(ORDER_ACTIONS.REFRESH_ORDER_SUMMARY, true);
                    self.fetchData();
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error({ message: `Error creating receipts and disbursements.` });
                });
                self.$rqBusy.wait(promise);
                return promise;
            },
            doDeleteReceiptsAndDisbursement(){
                const self = this;
                let promise = self.$api.CheckWritingApi.doDeleteReceiptsAndDisbursements(self.orderId);
                return self.$rqBusy.wait(promise)
                    .then(result => {
                        self.$store.dispatch(ORDER_ACTIONS.REFRESH_ORDER_SUMMARY, true);
                        self.fetchData();
                        self.$toast.success("Successfully deleted receipts and disbursements.");
                        return true;
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Error deleting receipts and disbursements.");
                        return error;
                    });
            },
            async initNewInvoice() {
                const self = this;
                try {
                    let promise = self.$api.InvoiceApi.addForOrderId(self.orderId)
                    let result = await self.$rqBusy.wait(promise);
                    self.invoices.push(result);

                    await self.$nextTick();

                    self.setStoredInvoices();

                    await self.fetchData();
                }
                catch(error) {
                    let message = 'Error adding Invoice';
                    self.$log.fatal(`InvoiceDetail - ${message}`, error);
                    self.$toast.error(message);
                }
            },
            fetchData(){
                const self = this;
                self.errorMessage = "";
                let apiPromise = self.$api.InvoiceApi.getDetails(self.orderId, self.invoiceId);
                return self.$rqBusy.wait(apiPromise)
                    .then(async result =>{
                        self.mapData(result);
                        await self.$nextTick();
                        self.setEditPermission();
                        return result;
                    }).catch(error => {
                        let message = 'Error retrieving Invoice data';
                        self.$log.fatal(`InvoiceDetail - ${message}`, error);
                        self.$toast.error(message);
                    });
            },
            onAddInvoice(){
                if(!this.canAddInvoice) return;
                this.$router.push({ name:"o-inv:invoice", params: { invoiceId: 0} });
            },
            onDeleteInvoice(){
                const self = this;
                this.save(false,true);
                if(!self.canDeleteThisInvoice) return;

                let okHandler = function (args) {
                    self.delete().then(() =>{
                        if(self.invoices.length > 0){
                            self.onViewInvoiceList();
                        }
                        else{
                            self.onAddInvoice();
                        }
                    });

                    return true;
                };

                self.$dialog.confirm(
                    "Confirm Delete",
                    `Are you sure you want to delete this Invoice?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});

            },
            onDeleteReceiptsAndDisbursements(e) {
                const self = this;
                if(!self.allowDeleteReceiptsAndDisbursements) return;
                let ok = function (args) {
                    self.errorMessage = "";
                    self.canDeleteReceiptsAndDisbursements();
                };
                self.$dialog.confirm("Confirm Delete", "Are you sure you want to delete all Receipts and Disbursements?", ok, null, { cancelTitle: "No", okTitle: "Yes"});
            },
            onCreateReceiptsAndDisbursements(e) {
                const self = this;
                if(!self.allowCreateReceiptsAndDisbursements) return;
                if(self.receiptsAndDisbursementsAvailability.canCreate){
                    if(self.receiptsAndDisbursementsAvailability.settlementStatementSelections.length === 0){
                        self.errorMessage = "No settlement statements exist on this file to create receipts and disbursements";
                    }
                    else if(self.selectedSource){
                        self.canCombineFunds(self.selectedSource);
                    }
                    else{
                        self.showSettlementStatementSelectionDialog();
                    }
                }
                else{
                    self.errorMessage = "Receipts and Disbursements have already been created on this file";
                }
            },
            onCancel(){
                const self = this;
                self.invokeDetailSectionMethod("customDataResetValidation");
                //user is cancelling after adding a new invoice that hasn't been saved yet and there's multiple invoices on the file
                if(!self.hasChanges && self.isNewInvoice && self.showInvoiceDropDown){
                    self.onViewInvoiceList();
                    return;
                }

                if(!self.hasChanges && !self.readOnly && !self.childComponentsHaveChanges()){
                    self.$toast.info("No changes detected.");
                    return;
                }

                let confirmMessage = (self.readOnly)
                    ? "Discard changes and return data?"
                    : "You have made changes to this invoice.  Do you want to cancel and lose your changes?";

                let ok = () => {
                    self.isCancelled = true;
                    if(self.readOnly)
                    {
                        self.$router.push({ name: "o-inv:invoices" });
                    }
                    else{
                        self.cancelChildComponentChanges();
                        self.fetchData();
                        self.invokeDetailSectionMethod("customDataInitialize");
                    }

                    return true;
                }
                self.$dialog.confirm( "Confirm Cancel", confirmMessage, ok, null);
            },
            onViewInvoiceList(){
                const self = this;

                if(!self.hasChanges && self.isNewInvoice && self.showInvoiceDropDown){
                    self.invoices.pop(); //remove the last item added to the array
                    self.setStoredInvoices();
                }
                if(self.invoices.length === 1){
                    let params = { invoiceId: self.invoices[0].invoiceID };
                    this.$router.push({ name:"o-inv:invoice", params });
                }
                else
                    self.$router.push({ name: "o-inv:invoices" });
            },
            onSave(e){
                let userInitiated = _.get(e, "userInitiated", false);
                this.save(userInitiated);
            },
            saveChildComponents(){
                const self = this;
                //commit grid data in child components
                let invoiceDetailSave = self.invokeDetailSectionMethod("onSave");
                let paymentSectionSave = self.invokePaymentSectionMethod("onSave");
                let creditMemoSectionSave = self.invokeCreditMemoSectionMethod("onSave");
                return Promise.all([invoiceDetailSave, paymentSectionSave, creditMemoSectionSave]);
            },
            showSettlementStatementSelectionDialog(){
                const self = this;
                self.$dialog.promptSelect({
                    title: "Settlement Statement Selection",
                    label: "Reconciliation",
                    inputId: "drp_settlement_statement",
                    isRequired: true,
                    items: self.receiptsAndDisbursementsAvailability.settlementStatementSelections,
                    valueExpr: "description",
                    displayExpr: "description",
                    onOk: async e => {
                        if(e.selectedValue) {
                            self.selectedSource = _.find(self.receiptsAndDisbursementsAvailability.settlementStatementSelections, b => b.description === e.selectedValue);
                            self.canCombineFunds(self.selectedSource);
                        }
                        return true;
                    }
                });
            },
            updateInvoicePremiumValues(){
                const self = this;
                if(!self.invoiceDetail.isAgentPremiumOverridden){
                    self.invoiceDetail.agentPremiumOverride = self.storedRateOrderInfo.agentPremiumCalculated;
                }
                if(!self.invoiceDetail.isUnderwriterPremiumOverridden){
                    self.invoiceDetail.underwriterPremiumOverride = self.storedRateOrderInfo.underwriterPremiumCalculated;
                }
                self.invoiceDetail.agentPremiumCalculated = self.storedRateOrderInfo.agentPremiumCalculated;
                self.invoiceDetail.underwriterPremiumCalculated = self.storedRateOrderInfo.underwriterPremiumCalculated;
                if (self.isTitleOnly) {
                    let request = {
                        invoiceID: self.invoiceDetail.invoiceID,
                        orderID: self.orderId,
                        loanIDs: _.map(self.loans, "loanID"),
                        fillType: InvoiceFillType.Premiums
                    }
                    self.onFillInvoice(request);
                }
            },
            updateInvoiceRecordingFeeValues(){
                const self = this;
                if (self.isTitleOnly) {
                    let request = {
                        invoiceID: self.invoiceDetail.invoiceID,
                        orderID: self.orderId,
                        loanIDs: _.map(self.loans, "loanID"),
                        fillType: InvoiceFillType.RecordingFeesAndTransferTaxes
                    }
                    self.onFillInvoice(request);
                }
            },
            save(userInitiated = false, forceSave = false){
                const self = this;
                self.validate();
                self.customDataIsValid = self.invokeDetailSectionMethod("customDataValidate");

                let originalId = self.invoiceId;
                if(self.readOnly){
                    GlobalEventManager.saveCompleted({ success: true });
                    return Promise.resolve(true);
                }

                if(self.errorMessage.length > 0 || !self.customDataIsValid){
                    GlobalEventManager.saveCompleted({ success: false });
                    self.$toast.error("Please correct any validation errors with this invoice and try again.");
                    return Promise.resolve(false);
                }

                let childComponentSavePromise = self.saveChildComponents();

                let savePromise = childComponentSavePromise.then(() => {
                    let customDataChanges = _.differenceWith(self.customData, self.originalCustomData, _.isEqual);
                    if(!forceSave){
                        if(!self.hasChildChanges &&  (
                                (self.isNewInvoice && !userInitiated)
                                || (!self.hasChanges && customDataChanges.length === 0)
                            )
                        ){
                            if(userInitiated)
                                self.$toast.info("No changes detected.");
                            return Promise.resolve(true);
                        }
                    }

                    //only send changed data
                    let payload = _.clone(self.invoiceDetail);
                    payload.deletedInvoiceLines = self.invoiceDetail.deletedInvoiceLines;
                    payload.deletedCreditMemos = self.invoiceDetail.deletedCreditMemos;
                    payload.deletedInvoicePayments = self.invoiceDetail.deletedInvoicePayments;
                    payload.invoiceLines = [];
                    payload.creditMemos = [];
                    payload.invoicePayments = [];

                    _.forEach(self.invoiceDetail.invoiceLines, i => {
                        if(i.isNew){
                            payload.invoiceLines.push(i)
                        }
                        else{
                            let original = _.find(self.originalData.invoiceLines, o => {
                                return o.invoiceLineID === i.invoiceLineID;
                            });
                            let changes = self.getAuditChanges(original, i);
                            if(changes.length > 0)
                                payload.invoiceLines.push(i);
                        }

                    });

                    _.forEach(self.invoiceDetail.creditMemos, i => {

                        let original = _.find(self.originalData.creditMemos, o => {
                            return o.creditMemoID === i.creditMemoID;
                        });
                        if(_.isNil(original)){
                            payload.creditMemos.push(i);
                            return true;
                        }
                        let changes = self.getAuditChanges(original, i);
                        if(changes.length > 0)
                            payload.creditMemos.push(i);
                    });

                    _.forEach(self.invoiceDetail.invoicePayments, i => {
                        if(i.isNew){
                            payload.invoicePayments.push(i)
                        }
                        else{
                            let original = _.find(self.originalData.invoicePayments, o => {
                                return o.invoicePaymentID === i.invoicePaymentID;
                            });
                            let changes = self.getAuditChanges(original, i);
                            if(changes.length > 0)
                                payload.invoicePayments.push(i);
                        }

                    });

                    let promise = self.$api.InvoiceApi.updateDetails(self.orderId, payload)
                        .then(result =>{
                            self.mapData(result);
                            if(!self.readOnly)
                            {
                                if(!forceSave)
                                    self.$toast.success("Invoice saved successfully.");
                                self.cancelChildComponentChanges();
                            }

                            if(userInitiated && originalId === 0){
                                let params = {invoiceId: result.invoiceID };
                                this.$router.push({ name:"o-inv:invoice", params });
                            }

                            // Remove the blank line(s) in the datagrid(s)
                            self.invokeDetailSectionMethod("refresh");
                            self.invokePaymentSectionMethod("refresh");

                            if(originalId === 0)
                            {
                                // when adding new invoice, use the newly created invoiceID for customData
                                customDataChanges = _.map(customDataChanges, x => {
                                                        x.referenceTablePKValue = result.invoiceID;
                                                        return x;
                                                    });
                            }

                            if(customDataChanges.length > 0) {
                                return self.$rqBusy.wait(self.$api.CustomDataApi.saveCustomData(customDataChanges))
                                        .then(() => result);
                            }
                            return result;
                        })
                        .then(result => {
                            if(originalId !== 0)
                                self.invokeDetailSectionMethod("customDataInitialize");

                            self.customDataIsValid = true;
                            return true;
                        })
                        .catch(error => {
                            if(!self.readOnly)
                            {
                                let message = 'Error updating Invoice data';
                                self.$log.fatal(`InvoiceDetail - ${message}`, error);
                                self.$toast.error({ message: message });
                            }
                            return false;
                        });
                        return promise;
                    })
                    .then((result) => {
                        return self.$store.dispatch(INVOICE_ACTIONS.GET_INVOICES)
                                .then(() => result);
                    })
                    .then((result) => GlobalEventManager.saveCompleted({ success: result }));

                return self.$rqBusy.wait(savePromise);

            },
            delete(){
                const self = this;
                let invoiceID = self.invoiceDetail.invoiceID;
                let promise = self.$api.InvoiceApi.deleteInvoice(self.orderId, invoiceID);
                return self.$rqBusy.wait(promise)
                    .then(() =>{
                        _.remove(self.invoices, i => { return i.invoiceID === invoiceID; });
                        self.setStoredInvoices();
                    })
                    .catch(error => {
                        let message = `Error deleting Invoice: ${error.message}`;
                        self.$log.fatal(`InvoiceDetail - ${message}`, error);
                        self.$toast.error({ message: message });
                        return error;
                    });
            },
            childComponentsHaveChanges(){
                const self = this;
                return self.invokeDetailSectionMethod("invokeGridMethod", "hasEditData")
                    || self.invokePaymentSectionMethod("invokeGridMethod", "hasEditData")
                    || self.invokeCreditMemoSectionMethod("invokeGridMethod", "hasEditData");
            },
            cancelChildComponentChanges(){
                const self = this;
                self.invokeDetailSectionMethod("invokeGridMethod", "cancelEditData");
                self.invokePaymentSectionMethod("invokeGridMethod", "cancelEditData");
                self.invokeCreditMemoSectionMethod("invokeGridMethod", "cancelEditData");
            },
            mapData(data){
                const self = this;
                self.invoiceDetail = new InvoiceDetail(data);
                // self.invoiceDetail.invoiceDate = moment(self.invoiceDetail.invoiceDate) //removed .format('YYYY-MM-DD') + 'T00:00:00' as it was hard setting the time to 12am when adding invoice, comment noted without this some control is stripping off the milliseconds and triggering a change in hasChanges
                if (self.invoiceDetail.invoiceID === 0) {
                    self.invoiceDetail.invoiceDate = _.isNil(data?.invoiceDate) ? null : DateTime.fromISO(data?.invoiceDate, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATE_SHORT);
                }
                self.invoiceDetail.invoiceLines = _.map(data.invoiceLines, il => {return new InvoiceLineModel(il);});
                self.invoiceDetail.invoicePayments = _.map(data.invoicePayments, p => {return new InvoicePaymentModel(p);});
                self.invoiceDetail.creditMemos = _.map(data.creditMemos, cm => {return new CreditMemoModel(cm);})
                self.loans = data.loans;
                self.originalData = _.cloneDeep(self.invoiceDetail);

                self.invokeDetailSectionMethod("refresh");
                self.invokePaymentSectionMethod("refresh");
                self.invokeCreditMemoSectionMethod("refresh");
            },
            setEditPermission() {
                let isFileInUse = _.getBool(this.orderSummary, "isFileInUse");
                let ignoreFileInUse = _.getBool(this.orderSummary, "ignoreFileInUse");
                if (isFileInUse && !ignoreFileInUse || this.isOrderLocked) {
                    this.hasEditPermission = false;
                    this.$store.commit(SET_PAGE_READ_ONLY, true);
                    return;
                }
                this.hasEditPermission = !this.isOrderLocked
                    && this.localSecurity.Invoices_ScreenAccess === UserScreenAccessLevel.Full
                    && (this.localSecurity.InvoiceEditing === InvoiceEditing.Unrestricted
                        || (this.localSecurity.InvoiceEditing === InvoiceEditing.UnpaidOnly
                            && (_.getNumber(this, "invoiceDetail.payment", 0) === 0
                                || _.getNumber(this, "invoiceDetail.balance", 0) !== 0))
                        || (this.localSecurity.InvoiceEditing === InvoiceEditing.NewOnly && this.isNewInvoice));
                this.$store.commit(SET_PAGE_READ_ONLY, !this.hasEditPermission);
            },
            validate(){
                const self = this;
                self.errorMessage = "";
                let errors = self.invokeDetailSectionMethod("validate") || [];

                self.paymentsTabErrorCount = errors.length;

                if(errors.length > 0)
                {
                    self.errorMessage = errors.length >= 1 ? errors[0] : "";
                    return;
                }
                if(self.localSecurity.InvoicePreDateDays === -1 && self.localSecurity.InvoicePostDateDays === -1) {
                    self.errorMessage = errors.length >= 1 ? errors[0] : "";
                    return;
                }

                let invoiceDate = DateTime.fromISO(self.invoiceDetail.invoiceDate).startOf('day');
                if (invoiceDate.invalid) return errors; // invoice date not set return early
                let now = DateTime.fromISO(DateTimeHelper.nowTenantStartOfDay());
                let daysDiff = DateTimeHelper.diff(now, invoiceDate, 'days').days;

                self.detailsTabErrorCount = 0;

                if(daysDiff > 0
                    && self.localSecurity.InvoicePreDateDays > -1
                    && Math.abs(daysDiff) > self.localSecurity.InvoicePreDateDays){
                    self.errorMessage = "Your Invoice Pre Date permissions prevent the invoice date from being set this far prior to the current date";
                    self.detailsTabErrorCount = 1;
                    return;
                }

                if(daysDiff < 0
                    && self.localSecurity.InvoicePostDateDays > -1
                    && Math.abs(daysDiff) > self.localSecurity.InvoicePostDateDays){
                    self.errorMessage = "Your Invoice Post Date permissions prevent the invoice date from being set this far after the current date";
                    self.detailsTabErrorCount = 1;
                    return;
                }
            },
            onFillInvoice(e){
                const self = this;
                let originalId = self.invoiceId;
                self.save(false, true).then(() => {
                    e.invoiceID = self.invoiceDetail.invoiceID;
                    let promise = self.$api.InvoiceApi.fillInvoice(e.orderID, e.invoiceID, e).then(result => {
                        self.$toast.success("Invoice filled successfully.");
                        //need to re-route if originalId was 0
                        if(originalId === 0){
                            let params = {invoiceId: result.invoiceID };
                            this.$router.push({ name:"o-inv:invoice", params });
                        }
                        else{
                            self.mapData(result);
                        }
                    }).catch(error => {
                        let message = 'Error updating Invoice data';
                        self.$log.fatal(`InvoiceDetail - ${message}`, error);
                        self.$toast.error({ message: message });
                        GlobalEventManager.saveCompleted({ success: false });
                        return false;
                    });

                    self.$rqBusy.wait(promise);
                    return promise;
                });

            },
            onShowPremiums(){
                const self = this;
                self.$dialog.open({
                    title: "Premium Rates",
                    width: "95%",
                    minWidth: 1200,
                    height: "95%",
                    minHeight: 700,
                    component: PremiumsMain,
                    props: { noTitle: true, showHeaderCalculate: true },
                    onOk: e => e.component.save(true).then(self.updateInvoicePremiumValues).then(() => true)
                });
            },
            onShowRecordingFees(){
                const self = this;
                self.$dialog.open({
                    title: "Recording Fees & Taxes",
                    width: "95%",
                    minWidth: 1200,
                    height: "95%",
                    minHeight: 700,
                    component: RecordingFeesAndTaxes,
                    props: { noTitle: true, showHeaderCalculate: true },
                    onOk: e => e.component.save(e.component.activeTabIndex, true).then(self.updateInvoiceRecordingFeeValues).then(() => true)
                });
            },
            onInvoiceDropdownChange(e) {
                if(_.isNil(e.event) || _.isNil(e.previousValue)) return;

                const self = this;
                if(!self.hasChanges && self.isNewInvoice && self.showInvoiceDropDown){
                    self.invoices.pop(); //remove the last item added to the array
                    self.setStoredInvoices();
                }

                this.$router.push({ name: 'o-inv:invoice', params: { invoiceId: e.value } });
            },
            onReorderInvoiceLines() {
                this.initInvoice();
            },
            setStoredInvoices() {
                return this.$store.commit(INVOICE_MUTATIONS.SET_ORDER_INVOICES, this.invoices.slice());
            },
            invokeDetailSectionMethod(method, ...params) {
                return this.invokeComponentMethod("invoiceDetailSection", method, ...params);
            },
            invokePaymentSectionMethod(method, ...params) {
                return this.invokeComponentMethod("paymentSection", method, ...params);
            },
            invokeCreditMemoSectionMethod(method, ...params) {
                return this.invokeComponentMethod("creditMemoSection", method, ...params);
            },
            invokeComponentMethod(ref, method, ...params) {
                return _.invoke(this, `$refs.${ref}.${method}`, ...params);
            }
        }
    }
</script>
