<template>
    <div id="closing-main" class="rq-container content-wrapper">
        <rq-banner
            variant="error"
            message="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="hasErrors"
            sticky
        />
        <rq-page-section 
            title="Signing" 
            headerSize="lg"
            :flex-full="showNoDataMessage"
            :flex-body="showNoDataMessage"
            borderless>
            <template #header-actions>
                <ul class="nav config-actions">
                    <li class="nav-item">
                        <b-button
                            automation_id="btn_add_closing"
                            :disabled="readOnly || getAreNonPaperSigningsComplete()"
                            variant="theme"
                            @click="onAdd"
                            >Add
                        </b-button>
                    </li>
                </ul>
                <div class="row mb-1">
                    <div class="col-auto ms-auto">
                        <rq-section-expand-collapse-all
                            section-group="closing-group"
                        />
                    </div>
                    <div v-if="pavasoEnabled && items.length > 0" 
                        class="col-auto ms-auto" 
                        v-rq-tooltip.hover.top="{ title: pavasoPartyRoleIdentifier }">
                            <FontAwesomeIcon icon="fas fa-info-circle" class="text-dark" />
                    </div>
                </div>
            </template>
            <RqNoData v-if="showNoDataMessage" size="lg" text="This file is eligible to close with Pavaso. Click &quot;Add&quot; to add a signing." />
        </rq-page-section>

        <rq-section-card
            :title="getDescriptionTitle(item.description)"
            section-group="closing-group"
            collapsible
            v-for="(item, index) in items"
            :key="item"
        >
            <signing-form
                :ref="'signingForm-' + index"
                v-model:item="items[index]"
                :canSignOrderSigning="canSignOrderSigning(item)"
                :fileScanDocuments="fileScanDocuments"
                :isUniqueSigningDate="isUniqueSigningDate"
                @open-tagging-session="openTaggingSession"
                @onSignDocuments="onSignDocuments"
                @onDelete="onDelete"
                :isComplete="getIsSigningComplete(item)"
            />
        </rq-section-card>
    </div>
</template>

<script>
import { computed } from "vue";
import { mapState, mapGetters } from "vuex";
import { OrderSigning } from "../models";
import { GlobalEventManager } from "@/app.events";
import SigningForm from "./SigningForm.vue";
import { ORDER_ACTIONS } from '@/store/actions';
import { useLicenseStore } from "@/store/modules/license";
import { FileScanDocumentDto }  from "../../../document-mgmt/models";
import { PavasoTaggingWrapper } from "@/integrations";
import { DateTime } from "luxon";
import { SigningDocumentStatus } from "../enum";
export default {
    name: "Signings",
    components: { SigningForm },
    data() {
        return {
            items: [],
            fileScanDocuments: [],
            originalData: [],
            deletedItems: [],
            hasErrors: false,
            pavasoPartyRoleIdentifier: ""
        };
    },
    setup(){
         const licenseStore = useLicenseStore();

        const pavasoEnabled = computed(() => licenseStore.features?.pavasoDigitalSignings);

        return {
            pavasoEnabled
        };
    },
    created() {
        const self = this;
        GlobalEventManager.onSave(self, self.onSave);
        GlobalEventManager.onCancel(self, self.onCancel);
        self.$rqBusy.wait(self.fetchData(true));
    },
    computed: {
        ...mapState({
            readOnly: state => state.isPageReadOnly,
            order: state => state.orders.order,
            orderSummary: state => state.orders.orderSummary,
            properties: state => state.properties.properties
        }),
        orderId() {
            return _.getNumber(this, "$route.params.orderId", 0);
        },
        hasSignings(){
            return this.originalData?.length > 0;
        },
        ...mapGetters(["lookupHelpers", "lookupItems"]),
        signingStatuses() {
            return _.filter(
                this.lookupHelpers.getAllLookupItems(
                    this.lookupItems.SIGNING_STATUSES
                ),
                (item) => item.inactive != true
            );
        },
        signingTypes() {
            return _.filter(
                this.lookupHelpers.getAllLookupItems(
                    this.lookupItems.CLOSING_TYPES
                ),
                (item) => item.inactive != true
            );
        },
        showNoDataMessage() {
            return this.orderSummary.isEligibleForPavasoSigning && _.isEmpty(this.items);
        },
        firstProperty(){
            return _.first(this.properties)?.property;
        },
        isPropertyInfoComplete(){
            return !_.isEmpty(this.firstProperty?.address1) && !_.isEmpty(this.firstProperty?.city) && !_.isEmpty(this.firstProperty?.state) && !_.isEmpty(this.firstProperty?.zip) && (this.firstProperty?.zip?.length === 5 || this.firstProperty?.zip?.length === 10);
        },
        hasPavasoMinimumRequiredInfo(){
            const self = this;
            return !_.isNil(self.order.closeDate) && 
                !_.isNil(self.order.titleCompanyCompanyID) &&
                !_.isNil(self.order.closeAgentStaffID);
        },
    },
    watch: {
    },
    beforeUnmount() {
        GlobalEventManager.unregister(this);
    },
    methods: {
        async openTaggingSession(document){
            const self = this;

            let dialogID = null;

            function onClickBackButton (){
                self.$dialog.close(dialogID);
            }

            let tokenPromise = self.$api.OrderSigningApi.getPavasoTaggingToken();
            let tokenResult = await self.$rqBusy.wait(tokenPromise);

            if (document.orderSigningDocumentStatusID == SigningDocumentStatus.NeedsReview) {
                document.orderSigningDocumentStatusID = SigningDocumentStatus.InProgress;
                let apiPromise = self.$api.OrderSigningApi.updateOrderSigningDocument(document);
                await self.$rqBusy.wait(apiPromise);
                await self.$rqBusy.wait(self.fetchData());
            }

            const taggingFragmentConfig = {
                documentName: document.description,
                orderGuid: self.order.pavasoOrderGuid,
                isOpen: true,
                token: tokenResult.token,
                refreshToken: tokenResult.refreshToken,
                onClickBackButton: onClickBackButton
            };

            dialogID = self.$dialog.open({                    
                title: `Pavaso Tagging`,
                height: "95%",
                width: "95%",
                cancelTitle: "Close",
                component: PavasoTaggingWrapper,
                cancelOnly: true,
                scrollable: false,
                props: {
                    integrationConfig: taggingFragmentConfig
                }
            });
        },
        getDescriptionTitle(description){
            return _.isEmpty(description) ? '(No Description)' : description;
        },
        onCancel() {
            const self = this;
            if (self.$rqBusy.isBusy()) return;
            if(!self.hasChanges()) {
                self.$toast.info("No changes detected.");
                return;
            }
            self.$dialog.confirm("Confirm", "Discard changes and reload data?", () => {
                self.$rqBusy.wait(self.fetchData());
            });
        },
        async onSave(e) {
            const self = this;
            var success = await self.$rqBusy.wait(self.saveOrderAndPavasoOrder(e?.userInitiated, false));
            GlobalEventManager.saveCompleted({ success: success });
        },
        async saveOrderAndPavasoOrder(userInitiated, savePavasoOverride){
            const self = this;

            var hasChanges = self.hasChanges();

            var saveSuccess = await self.save(userInitiated);

            if (!saveSuccess) return false;

            let result = await self.$api.OrderSigningApi.getOrderSignings(self.orderId);
            var items = _.map(result, (s) => new OrderSigning(s));
            let currentStatus = _.find(self.signingStatuses, x => x.id == items[0]?.signingStatusID);
            if(currentStatus?.name == 'Complete') {
                self.$toast.error("Signing is complete, unable to sign in Pavaso.");
                return false;
            }

            if(savePavasoOverride || ((_.isNil(self.order.pavasoOrderGuid) || hasChanges) && self.canSavePavasoOrder(items))){
                var savePavasoOrderSuccess = await self.savePavasoOrder();

                if(!savePavasoOrderSuccess){
                    return false;
                }
            }

            if(userInitiated)//if not user initiated we don't want the signings forms to refresh at this stage
            {
                self.items = items;
            }

            return true;
        },
        onAdd(e) {
            const self = this;
            let options = {
                ordersID: self.orderId,
                orderSigningID: _.parseNumber(_.uniqueId()) * -1,
                signingStatusID: _.find(self.signingStatuses, y => y.name === 'New').id,
            };
            self.items.push(new OrderSigning(options));
        },
        onDelete(item) {
            const self = this;
            self.items = _.filter(self.items, i => i.orderSigningID !== item.orderSigningID);
            self.deletedItems.push(item);
        },

        async onSignDocuments(orderSigning){
            const self = this;
            var success = await self.$rqBusy.wait(self.signDocuments(orderSigning));
            GlobalEventManager.saveCompleted({ success: success });
        },

        async signDocuments(orderSigning){
            const self = this;
            try {
                let orderIsReady = await self.$api.OrderSigningApi.getPavasoOrderIsReady(self.orderId);

                if(!orderIsReady){
                    self.$toast.warn("Pavaso is currently processing your order, please wait for a few minutes before starting the signing.");
                    return false;
                }

                var saveSuccess = await self.saveOrderAndPavasoOrder(false, true);

                if (!saveSuccess) return false;

                let signingStatus = await self.$api.OrderSigningApi.getSigningSessionInProgress(self.order.pavasoOrderGuid);

                if(signingStatus){
                    self.$toast.error("Another signing for this file is already in progress.");
                    return false;
                }

                let signingDeeplink = await self.$api.OrderSigningApi.getPavasoDeepLink(orderSigning.orderSigningID);

                if(!signingDeeplink){
                    self.$toast.error("An issue occurred while generating Pavaso signing link.");
                }
                else{
                    window.open(signingDeeplink,'_blank');
                }
                
                var fetchDataSuccess = await self.fetchData();
                if(!fetchDataSuccess) return false;

                return true;
            } catch (err) {
                self.$toast.error("An issue occurred while generating Pavaso signing link.");
                console.error(err);
                return false;
            }
        },
        async savePavasoOrder(){
            const self = this;
            try {

                await self.$api.OrderSigningApi.savePavasoOrder(self.orderId);

                await this.$store.dispatch(ORDER_ACTIONS.GET_ORDER_DATA, { refresh: true });

                self.$toast.success("Saved Pavaso Order Successfully.");
                return true;
            } catch (err) {
                self.$toast.error("An issue occurred while saving Pavaso Order");
                console.error(err);
                return false;
            }
        },

        async fetchData(initialLoad = false) {
            const self = this;
            try {
                let result = await self.$api.OrderSigningApi.getOrderSignings(
                    self.orderId
                );
                self.items = _.map(result, (s) => new OrderSigning(s));
                self.originalData = _.map(result, (s) => new OrderSigning(s));
                self.deletedItems = [];

                let documentsResult = await self.$api.FileScanApi.getFileScanDocuments(self.orderId);
                self.fileScanDocuments = _.uniqBy(_.map(documentsResult, i => new FileScanDocumentDto(i)), 'fileScanDocumentID');
            } catch (err) {
                self.$toast.error(
                    "An issue occurred while retrieving Order Signings."
                );
                console.error(err);
                return false;
            }

            try {
                if(_.isEmpty(self.pavasoPartyRoleIdentifier)) {
                    let partyRoleIdentifierOutput = await self.$api.OrderSigningApi.getPavasoPartyRoleIdentifier(
                        self.orderId
                    );
                    self.pavasoPartyRoleIdentifier = `Horizon Pavaso PRID ${partyRoleIdentifierOutput}`;
                }
            } catch(err) {
                console.error(err);
                return false;
            }

            if(initialLoad && (_.isNil(self.order.pavasoOrderGuid) && self.canSavePavasoOrder(self.items))){
                await self.savePavasoOrder();
            }

            return true;
        },

        async save(userInitiated) {
            const self = this;
            try {
                if (self.readOnly) return false;
                if (!self.hasChanges()) {
                    if(userInitiated) self.$toast.info({ message: "No changes detected" });
                    return true;
                }

                self.hasErrors = false;

                _.forEach(self.items, (item, index) => {
                    const signingForm = self.$refs["signingForm-" + index][0];

                    if (signingForm.touch()) {
                        self.hasErrors = true;
                    }
                });

                if (self.hasErrors) {
                    return false;
                }

                let saveItems = self.items;
                await self.$api.OrderSigningApi.saveDeleteRecords(
                    saveItems,
                    self.deletedItems
                );

                self.$toast.success("Saved Signings Successfully.");
                return true;
            } catch (err) {
                self.$toast.error("An issue occurred while saving Signings");
                console.error(err);
                return false;
            }
        },
        getIsSigningComplete(item){
            const self = this;
            let currentStatus = _.find(self.signingStatuses, x => x.id === item.signingStatusID)
            if (currentStatus?.name == 'Complete')
            {
                return true
            } else { return false }
        },
        getAreNonPaperSigningsComplete()
        {
            const self = this;
            let paperTypeID = _.find(self.signingTypes, y => y.name === 'Paper')?.id
            let completeStatusID = _.find(self.signingStatuses, y => y.name === 'Complete')?.id;
            let nonPaperSignings = _.filter(self.items, x => x.signingTypeID !== paperTypeID);

            if(nonPaperSignings.length == 0) return false;

            let areNonPaperSigningsComplete = !_.some(nonPaperSignings, y => y.signingStatusID !== completeStatusID);
            return areNonPaperSigningsComplete;
        },
        hasChanges() {
            //if the arrays are different length, then there are changes.  Deletes have already been saved
            if (this.items.length !== this.originalData.length) {
                return true;
            }
            //need to compare the two arrays for changes
            let changes = this.getAuditChanges(this.originalData, this.items);
            return changes.length > 0;
        },
        isValidSigningType(id){
            return _.includes([1, 3, 4, 5], id);
        },
        canSavePavasoOrder(items){
            const self = this;

            let allAttendeesAreValid = _.every(items, i => i.attendeeContactInfoValid && i.attendeeAddressInfoValid);
            let hasAtLeastOnePavasoSigning = _.some(items, i => self.isValidSigningType(i.signingTypeID));

            return self.pavasoEnabled && 
                items.length > 0 &&
                hasAtLeastOnePavasoSigning &&
                allAttendeesAreValid &&
                self.hasPavasoMinimumRequiredInfo &&
                self.isPropertyInfoComplete;
        },
        canSignOrderSigning(orderSigning){
            const self = this;

            let hasDocuments = orderSigning.orderSigningFileScanDocuments?.length > 0;
            let signingAttendeesAreValid = orderSigning.attendeeContactInfoValid;

            let validSigningType = self.isValidSigningType(orderSigning.signingTypeID);//IPEN, Remote On-line Notarization, Multi Device CLosing, Remote Ink Notorization
            
           return !self.getIsSigningComplete(orderSigning) && 
                orderSigning.orderSigningID !== 0 && 
                validSigningType && 
                hasDocuments && 
                signingAttendeesAreValid && 
                self.hasPavasoMinimumRequiredInfo &&
                !_.isNil(orderSigning.signingDate) &&
                self.isPropertyInfoComplete;
        },
        isUniqueSigningDate(id, value, signingTypeID) {
            return _.isNil(signingTypeID) 
                || !this.isValidSigningType(signingTypeID) 
                || _.isEmpty(value) 
                || _.every(this.items, s => 
                    _.isEmpty(s.signingDate) 
                    || _.isNil(s.signingTypeID) 
                    || !this.isValidSigningType(s.signingTypeID) 
                    || s.orderSigningID == id 
                    || !DateTime.fromISO(s.signingDate).toUTC().hasSame(DateTime.fromISO(value).toUTC(), "minute"));
        }
    },
};
</script>
