<template>
    <div class="content-wrapper rq-container">
        <rq-banner
            ref="errorBanner"
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="errorMessage.length > 0"
        />
        <fieldset class="py-3" :disabled="readOnly">
            <div class="row">
                <div class="col col-3 form-group">
                    <label for="dtp_check_date">Date</label>
                    <rqdx-date-box
                        id="dtp_check_date"
                        :disabled="true"
                        v-model="item.checkDate"
                    />
                </div>
                <div :class="{ 'col col-3 form-group form-required':true, 'has-error':v$.item.amount.$error }">
                    <label for="txt_amount">Amount</label>
                    <rq-input-number
                        automation_id="txt_amount"
                        ref="txt_amount"
                        formatType="money"
                        defaultValue="0"
                        :minValue="0"
                        :disabled="!isNew || readOnly"
                        decimals="2"
                        input-group
                        no-prefix
                        prependIcon="fas fa-dollar-sign"
                        v-model="v$.item.amount.$model"
                        />
                        <rq-validation-feedback
                            :messages="{ 'Amount is required': v$.item.amount.required.$invalid,
                                         'Amount must be greater than Zero': v$.item.amount.isGreaterThanZero.$invalid,
                                        }"
                        />
                </div>
                <div class="col col-3 form-group">
                    <label for="txt_check_number">Number</label>
                    <input
                        automation_id="txt_check_number"
                        class="form-control"
                        type="text"
                        readonly
                        v-model="item.numberDisplay"
                        />
                </div>
                <div class="col col-3 form-group">
                    <label for="txt_status">Status</label>
                    <input automation_id="txt_status"
                        class="form-control"
                        type="text"
                        placeholder="Check Status"
                        readonly
                        :value="getStatus(item.checkStatus)"
                        />
                </div>
            </div>
            <div class="row">
                <div :class="{ 'col col-6 form-group form-required':true, 'has-error':v$.item.payee.$error }">
                    <label for="txt_payee">Pay to the order of</label>
                    <RqFauxSelectBox
                        automation_id="btn_drp_down_txt_payee"
                        :display-value="item.payee"
                        :items="payeeSelectOptions"
                        @item-click="onPayeeItemClick"
                    />
                    <rq-validation-feedback :offset="38">Payee is required.</rq-validation-feedback>
                </div>
                <div class="col col-6 form-group" v-if="isNew">
                    <label for="drp_accountingCodeID">Accounting Code</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_accountingCodeID', id: 'drp_accountingCodeID' }"
                        :items="accountingCodes"
                        :disabled="readOnly || hasReconciliationDate"
                        value-expr="id"
                        display-expr="name"
                        v-model="item.accountingCodeID"
                        :search-enabled="true"
                    />
                </div>
                <div class="col col-3 form-group" v-else>
                    <label for="txt_reconciliation_date">Recon Date</label>
                    <input automation_id="txt_reconciliation_date"
                        class="form-control"
                        type="text"
                        :value="formatDate(item.reconciliationDate)"
                        placeholder="Reconciliation Date"
                        readonly
                        />
                </div>
                <div class="col col-3 form-group" v-if="!isNew">
                    <label for="txt_fed_reference_number">Fed Reference No.</label>
                    <input
                        automation_id="txt_fed_reference_number"
                        class="form-control"
                        type="text"
                        readonly
                        v-model="item.fedRefNumber"
                        />
                </div>
            </div>
            <div class="row">
                <div class="col-6 form-group mb-0" v-if="isNew">
                    <label class="form-control-label" for="txt_description">Description</label>
                    <input automation_id="txt_description"
                        class="form-control"
                        type="text"
                        v-model="item.description"
                        maxlength="60"
                        />
                </div>
                <div :class="{ 'col col-6 form-group mb-0': isNew, 'col col-12 form-group mb-0': !isNew }">
                    <label for="txt_memo">Memo</label>
                    <input automation_id="txt_memo"
                        class="form-control"
                        type="text"
                        v-model="item.memo"
                        maxlength="200"
                        :disabled="hasReconciliationDate || readOnly"
                        placeholder="Memo"
                        />
                </div>
            </div>
        </fieldset>
        <rqdx-action-data-grid
            ref="dataGrid"
            title-size="md"
            v-if="!isNew"
            automation_id="tbl_check_lines"
            class="rq-cw-modal-datagrid"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            @delete="onDeleteDetailItem"
            :read-only="readOnly"
            :persist-state="false"
            fixed-header
            hide-search
            hide-show-column-chooser
            :rq-editable="!readOnly"
            rq-filters>
            <template #toolbar>
                <ul class="nav me-auto">
                    <li class="nav-item" v-rq-tooltip.hover.top="{ title: addCheckLineToolip }">
                        <b-button
                            automation_id="btn_add_check_line"
                            class="btn btn-theme me-1"
                            variant="theme"
                            @click="onAddLine"
                            :disabled="!showAddLine || readOnly"
                            >Add Line
                        </b-button>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { DateTime } from "luxon";
    import { CheckShortDto, CheckLineDto }  from "../models";
    import { CheckStatus, CreatedFromSource } from '../enums';
    import FileContactSelection from "@file-shared/components/FileContactSelection";
    import CheckWritingManualPayee from "./CheckWritingManualPayee";
    import CompanyContactLookup from "@order-entry/contacts/components/CompanyContactLookup";
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import { UserSecuritySettings } from "@/shared/models/models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import RqFauxSelectBox from "@/shared/components/rq/RqFauxSelectBox.vue";
    const FINALIZED_RECON_WARNING = "Item is dated within a finalized reconciliation time period and will recalculate affected finalized reconciliations.";

    export default {
        name: "AddEditCheckForm",
        mixins: [GridSystemLookupMixin],
        props: {
            bank: {type: Object, required: true},
            check: {type: Object, required: true},
            checkLines: {type: Array, required: true, default: () => []},
            checkLineLookups: { type: Array, required: true, default: () => [] },
            summary: { type: Object, required: true, default: () => ({}) },
        },
        components: { RqFauxSelectBox },

        inject: ["dialogId"],

        setup: () => ({ v$: useVuelidate() }),

        data() {
            return {
                errorMessage: "",
                originalItem: {},
                originalDetail: [],
                item: {},
                itemDetail: [],
                ledgerSummary: {},
                detailUpdates: false
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                order: state => state.orders.order,
                orderSummary: state => state.orders.orderSummary,
                systemDefaults: state => state.system.systemDefaults,
                user: state => state.authentication.session.user,
                authSettings: state => state.authentication.session.settings,
                orderId: state => state.orders.orderId,
                contacts: state => state.orders.contacts.contacts,
            }),
            checkLineLimit() { return _.get(this, "bank.checkLineLimit", 0); },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            numberLabel() { return this.item.isWire ? 'Wire' : 'Check'; },
            hasReconciliationDate() { return !_.isNil(this.item.reconciliationDate); },
            hasPayee() { return _.getNumber(this, 'item.payeeCompanyID', 0) > 0 || _.getNumber(this, 'item.buyerSellerID', 0) || _.size(_.trim(this.item.payee)) > 0; },
            buyers() { return _.filter(this.contacts, c => c.role == "Buyer"); },
            sellers() { return _.filter(this.contacts, c => c.role == "Seller") },
            localSecurity(){
                return this.securitySettings.findValues([
                    "IsAdmin",
                    "IsEscrowAdmin",
                    "AllowCheckNumberChange",
                    "AllowDeleteCheck",
                    "CheckPreDateDays",
                    "CheckPostDateDays",
                    ]);
            },
            isCdf() { return _.isEqual(_.get(this, "summary.autoRecreateSource", -1), 3); },
            isNew() { return _.getNumber(this, 'item.checksID', 0) == 0; },
            readOnly() { return this.isFileLocked || this.item.checkStatus != CheckStatus.None || this.item.isTransfer; },
            showAddLine() { return this.checkLineLimit > 0 ? (this.itemDetail.length < this.checkLineLimit) : true; },
            warnFinalRecon() {
                return (!this.isNew && !_.isNil(this.originalItem.checkDate) && this.localSecurity.AllowFinalizedReconModifications && DateTime.fromISO(this.originalItem.checkDate).diff(DateTime.fromISO(this.order.lastFinalizedReconDate), "days").days <= 0)
                    || (this.isNew && !_.isNil(this.item.checkDate) && this.localSecurity.AllowFinalizedReconModifications && DateTime.fromISO(this.item.checkDate).diff(DateTime.fromISO(this.order.lastFinalizedReconDate), "days").days <= 0);
            },
            isSysAdmin(){
                let settings = new UserSecuritySettings(this.authSettings);
                return settings.findValue("IsSysAdmin");
            },

            addCheckLineToolip() {
                return this.showAddLine ? `Add Disbursement Line` : `Max Disbursement Line # of ${this.checkLineLimit} reached.`;
            },

            payeeSelectOptions() {
                const self = this;
                let result = [];

                const addBsItems = (items, headerText, itemType) => {
                    if(_.isEmpty(items)) return;
                    result.push({ text: headerText, isHeader: true });
                    let bsItems = _.map(items.slice(0, 6), (data, idx) => ({
                        key: `${itemType}_${idx}`,
                        automationId: `btn_file_${itemType}`,
                        name: "buyer-seller",
                        text: data?.name,
                        data
                    }));
                    for (var i in bsItems) {
                        const bsItem = bsItems[i];
                        const resultsWithData = _.map(_.filter(result, r => !!r.data), r => (r));
                        if (!resultsWithData.length) {
                            result.push(bsItem);
                        } else {
                            const isDistinct = !_.find(resultsWithData, r => (r.data.buyerSellerID == bsItem.data.buyerSellerID));
                            if (isDistinct) result.push(bsItem);
                        }
                    }
                };

                addBsItems(self.buyers, "Buyers", "buyer");
                addBsItems(self.sellers, "Sellers", "seller");

                if(!_.isEmpty(result))
                    result.push({ isDivider: true });

                let staticItems = [
                    { automationId: "btn_file_contacts", name: "contact", text: "File Contact" },
                    { automationId: "btn_company", name: "company", text: "Company" },
                    { automationId: "btn_manual", name: "manual", text: "Manual" }
                ];

                result.push(...staticItems);
                return result;
            }
        },
        created(){
            this.init();
            this.initNonReactiveVariables();
            this.initGridConfig();
        },
        mounted() {
            _.delay(() => {
                _.invoke(this, '$refs.txt_amount.focus');
            }, 250);
        },
        validations() {
            const self = this;

            var result = {
                item: {
                    payee: {
                        hasPayee(val) {
                            return self.hasPayee;
                        }
                    },
                    amount: {
                        required,
                        isGreaterThanZero(val) {
                            return _.gt(val, 0);
                        }
                    },
                }
            }
            return result;
        },
        methods: {
            allowEditAccountCodePermission(rowData) {
                //RQO-18744 - Can't edit account codes for issued disbursements in any case
                if (this.item.checkStatus === CheckStatus.Issued) return false;
                //RQO-16972 - If a User Edits their own Manual Check, they can edit account Code
                if (_.isEqual(this.user.fullName, this.item.usersDisplayName) && _.isEqual(_.get(rowData, "createSource"), CreatedFromSource.Manual)) return true;
                //RQO-16972 - If a User Edits any check line without an Account Code, they can set it
                if (_.getNumber(rowData, "accountingCodeID", 0) === 0) return true;
                //Otherwise, use system setting
                return this.systemDefaults.onlySystemAdminsCanEditAccountingCodes ? this.isSysAdmin : true;
            },

            formatDate(date) {
                return DateTime.fromISO(date).isValid ? DateTime.fromISO(date).toFormat('MM/dd/yyyy') : "";
            },

            gridHasChanges(){
                const self = this;
                if(!_.isEmpty(self.gridInstance) && self.gridInstance.hasEditData()) return true;
                return false;
            },

            getCheckLineLookup(lineStr) {
                if (_.isNullOrEmpty(lineStr)) return;
                return _.find(this.checkLineLookups, ["lineNumber", lineStr.toUpperCase()]);
            },

            getStatus(id) {
                if (_.isNil(id)) return '';
                return CheckStatus.displayValue(id);
            },

            getNextDetailLine() {
                let sequence = _.get(_.maxBy(this.itemDetail, "sequence"), "sequence", 0) + 1;
                let checksID = this.check.checksID;
                let createSource = CreatedFromSource.Manual;
                return {
                    sequence: sequence,
                    lineNumberStr: "",
                    checksID : checksID,
                    createSource: createSource
                };
            },

            init() {
                this.ledgerSummary = _.clone(this.orderSummary);
                this.originalItem = _.clone(this.check);
                this.item = _.clone(this.check);
                this.originalDetail = _.clone(this.checkLines);
                this.itemDetail = _.clone(this.checkLines);
                this.$emit(`${this.readOnly ? "disable" : "enable"}-ok`);
            },

            initGridConfig(){
                const self = this;
                let validateLineNumber = (item) => {
                    if (this.isCdf) {
                        let value = item.data.lineNumberStr;
                        let isAlphaNumeric = !!value?.match(/([A-Za-z][0-9]+)/gm);
                        if (isAlphaNumeric) return true;
                        return _.gte(_.parseNumber(item.data.lineNumberStr, 0), 0);
                    }
                    return _.gte(_.parseNumber(item.data.lineNumber, 0), 0);
                }

                self.gridConfig = {
                    allowColumnReordering: false,
                    focusedRowEnabled: false,
                    onEditorPreparing: self.onEditorPreparing,
                    disabled: self.readOnly,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [25,50,100], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    scrolling: { useNative: true },
                    columns: [
                        {
                            dataField: "lineNumberStr",
                            caption: "Line",
                            dataType: "string",
                            editCellTemplate: DxGridUtils.checkLineEditCellTemplate({
                                tooltipMessage: "Line Number Created from Settlement Statement is Unavailable for Edit.",
                                maxLength: 3
                            }),
                            setCellValue: function(rowData, value) {
                                let newValue = _.isNullOrEmpty(value) ? "" : value.toString().trim().toUpperCase();
                                rowData.lineNumber = newValue;
                                rowData.lineNumberStr = newValue;
                                let lookupInfo = self.getCheckLineLookup(newValue);
                                if (lookupInfo) {
                                    let lineAdd = _.parseNumber(newValue.substring(1));
                                    rowData.lineNumber = (lookupInfo.seedValue + lineAdd) * -1;
                                    rowData.description = lookupInfo.lineDescription;
                                    rowData.amount = lookupInfo.amount;
                                }
                            },
                            calculateCellValue: function(rowData){
                                return DxGridUtils.lineNumberStrDisplay(rowData);
                            },
                            validationRules: [
                                {
                                    type: "custom",
                                    message: "Invalid Line Number.",
                                    validationCallback: item => !self.isCdf ? true : validateLineNumber(item)
                                },
                            ],
                            width: 100,
                            showInColumnChooser: self.isCdf,
                            visible: self.isCdf
                        },
                        {
                            dataField: "lineNumber",
                            caption: "Line",
                            dataType: "number",
                            editorOptions: { maxLength: 4 },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxTextBox({
                                    value: cellInfo.value,
                                    showClearButton: false,
                                    maxLength: 4,
                                    valueChangeEvent: "change",
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            },
                            setCellValue: function(rowData, value) {
                                let newValue = _.isNullOrEmpty(value) ? "" : value.toString().trim().toUpperCase();
                                rowData.lineNumber = newValue;
                                let lookupInfo = self.getCheckLineLookup(newValue);
                                if (lookupInfo) {
                                    rowData.lineNumberStr = lookupInfo.lineNumber;
                                    rowData.description = lookupInfo.lineDescription;
                                    rowData.amount = lookupInfo.amount;
                                }
                            },
                            validationRules: [
                                {
                                    type: "custom",
                                    message: "Invalid Line Number.",
                                    validationCallback: item => self.isCdf ? true : validateLineNumber(item)
                                }
                            ],
                            width: 100,
                            showInColumnChooser: !self.isCdf,
                            visible: !self.isCdf
                        },
                        {
                            dataField: "description",
                            dataType: "string",
                            editorOptions: { maxLength: 60 },
                            editCellTemplate: function(cellElement, cellInfo) {
                                const lookupInfo = self.getCheckLineLookup(cellInfo.data.lineNumberStr);
                                const isSystem = _.parseBool(lookupInfo?.isSystem);
                                let $tooltip = isSystem ? $("<span/>")
                                    .append("System descriptions cannot be edited.")
                                    .dxTooltip({
                                        target:  cellElement,
                                        wrapperAttr: { class: "rq-dx-tooltip" },
                                        position: "top",
                                        showEvent:"mouseenter",
                                        hideEvent:"mouseleave",
                                    }) : null;
                                $("<div />").dxTextBox({
                                    value: cellInfo.value,
                                    disabled: isSystem,
                                    showClearButton: false,
                                    maxLength: 60,
                                    valueChangeEvent: "change",
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                })
                                .append($tooltip)
                                .appendTo(cellElement);
                            },
                            setCellValue: function(rowData, value) {
                                rowData.description = value;
                            },
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    message: "Total sum must be greater than 0.",
                                    validationCallback: item => self.isAmountGreaterThanZero([item.data])
                                }
                            ],
                            editorOptions: { format: {type: "currency", precision: 2}, showClearButton: true },
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "accountingCodeID",
                                dataType: "number",
                                caption: "Account Code",
                                editorOptions: { showClearButton: true },
                            },
                            lookupKey: self.lookupItems.ACCOUNTING_CODES,
                            regionId: self.order.regionID,
                            allowEdit:self.allowEditAccountCodePermission,
                            customSort: function(i) { return _.parseNumber(_.get(i, "data")); }
                        }),
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "TotalLabel",
                                column: "description",
                                alignment: "left",
                                displayFormat: "TOTAL",
                                cssClass: "rq-summary-label",
                                summaryType: "sum"
                            },
                            {
                                name: "CheckTotal",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                        ]
                    },
                    onInitNewRow(e) {
                        e.data = self.getNextDetailLine();

                        self.$nextTick().then(() => {
                        if(!self.showAddLine){
                            self.gridInstance.cancelEditData();
                        }
                        });
                    },
                };

                self.gridDataSource = {
                    key: self.detailKey,
                    load (loadOptions) {
                        return Promise.resolve(self.itemDetail);
                    },
                    insert: self.onDetailGridInsert,
                    update: self.onDetailGridUpdate
                };
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Disbursement";
                self.itemTypeNamePlural = "Disbursements";
                self.itemKey = "checksID";
                self.detailTypeName = "Disbursement Line";
                self.detailTypeNamePlural = "Disbursement Lines";
                self.detailKey = "checkLineID";
                self.statuses = CheckStatus.lookupItems;
                self.accountingCodes = self.lookupHelpers.getLookupItems(self.lookupItems.ACCOUNTING_CODES, self.order.regionID);
                self.selectionActions = [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.detailTypeName}`,
                        disabled: function(e) {
                            let zeroedOutItems = e.data.map(item => {
                                return { ...item, amount: 0 };})
                            return _.get(self, "itemDetail.length", 0) - _.get(e.data, "length") < 1
                                || self.readOnly
                                || !self.localSecurity.AllowDeleteCheck
                                || !self.isAmountGreaterThanZero(zeroedOutItems);
                        }
                    }
                ];
            },

            isAmountGreaterThanZero(items) {
                const self = this;
                let itemCheckLineIDs = items.map(item => item.checkLineID);
                let listSum = _.sumBy(self.itemDetail, i =>
                    !itemCheckLineIDs.includes(i.checkLineID) ? i.amount : 0
                );
                let itemSum = _.sumBy(items, 'amount');
                let totalSum = listSum + itemSum;
                return _.gt(totalSum, 0);
            },

            isUniqueLine(row){
                const self = this;
                if (_.isNullOrEmpty(row.lineNumber) && _.isNullOrEmpty(row.lineNumberStr)) return true;//only validate if the line number exists
                let dup = {};
                dup = _.find(self.itemDetail, (i) => {
                    if (self.isCdf) {
                        return  _.isEqual(_.trim(i.lineNumberStr), _.trim(row.lineNumberStr))
                                && _.parseNumber(_.get(i, self.itemKey, -1), -1) == _.parseNumber(_.get(row, self.itemKey, -1), -1)
                                && _.parseNumber(_.get(i, self.detailKey, -1), -1) != _.parseNumber(_.get(row, self.detailKey, -1), -1);
                    } else {
                        return  _.parseNumber(i.lineNumber, -1) === _.parseNumber(row.lineNumber, -1)
                                && _.parseNumber(_.get(i, self.itemKey, -1), -1) == _.parseNumber(_.get(row, self.itemKey, -1), -1)
                                && _.parseNumber(_.get(i, self.detailKey, -1), -1) != _.parseNumber(_.get(row, self.detailKey, -1), -1);
                    }
                });
                return _.isEmpty(dup);
            },

            onAddLine(e) {
                const self = this;
                if(self.readOnly || !self.gridInstance || !self.showAddLine) return;
                self.gridInstance.addRow();
                //if they are allowed to alter finalized recons, and alter the amount, then warn
                if (self.warnFinalRecon) {
                    self.errorMessage = FINALIZED_RECON_WARNING;
                }
            },

            setBuyerSellerPayee(item){
                this.item.buyerSellerID = item?.buyerSellerID;
                this.item.payee = item?.name;
                this.item.useForwardingAddress = item?.hasForwardingAddress;
            },

            onDeleteDetailItem(e) {
                if(!e || !e.data) return;
                const self = this;
                if( _.get(self, "itemDetail.length", 0) - _.get(e.data, "length") < 1 || self.readOnly || !self.localSecurity.AllowDeleteCheck) return;

                let ok = function (args) {
                    let ids = _.map(e.data, self.detailKey);
                    let apiPromise = self.$api.CheckWritingApi.deleteCheckLines(self.item.checksID, ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            let check = new CheckShortDto(result.checks[0]);
                            let checkLines = _.clone(self.itemDetail);
                            _.pullAllBy(checkLines, [{checksID: self.item.checksID}], self.itemKey);
                            _.each(result.checkLines, cl => {
                                checkLines.push(new CheckLineDto(cl));
                            });
                            self.ledgerSummary = result.orderSummary;
                            self.item.amount = self.originalItem.amount = check.amount;
                            self.item.description = self.originalItem.description = check.description;
                            self.item.lineCount = self.originalItem.lineCount = check.lineCount;
                            self.itemDetail = self.originalDetail = checkLines;
                            self.detailUpdates = true;
                            let message = ids.length > 1 ? `${ids.length} ${self.detailTypeNamePlural} were deleted.` : `${self.detailTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                            if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.detailTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.detailTypeName}.` });
                            }
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${e.data.length > 1 ? self.detailTypeNamePlural : self.detailTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onDetailGridInsert(values) {
                const self = this;
                let newItem = new CheckLineDto(values);
                let lookupInfo = self.getCheckLineLookup(newItem.lineNumberStr);
                if (lookupInfo) {
                    newItem.lineNumberStr = lookupInfo.lineNumber;
                    newItem.description = _.parseBool(lookupInfo?.isSystem) ? lookupInfo.lineDescription : newItem.description;
                }
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                if (_.size(changes) == 0) return Promise.resolve();
                return self.saveCheckLine(newItem, changes)
                    .then(result => {
                        let checkLines = _.clone(self.itemDetail);
                        checkLines.push(new CheckLineDto(result.checkLines[0]));
                        let check = new CheckShortDto(result.checks[0]);
                        self.ledgerSummary = result.orderSummary;
                        //only set the amount in case they haven't saved changes to the check
                        self.item.amount = self.originalItem.amount = check.amount;
                        self.item.lineCount = self.originalItem.lineCount = check.lineCount;
                        self.item.description = self.originalItem.description = check.description;
                        self.itemDetail = self.originalDetail = checkLines;
                        self.detailUpdates = true;
                        self.$toast.success({ message: `${self.detailTypeName} ${newItem.description || '(Blank)'} was created.` });
                    }).catch(error => {
                        self.$toast.error({ message: `Error creating ${self.detailTypeName}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onDetailGridUpdate(key, values) {
                const self = this;
                let detailIndex = _.findIndex(self.itemDetail, [self.detailKey, key]);
                if(detailIndex < 0) return;

                let originalItem = _.cloneDeep(self.itemDetail[detailIndex]);
                let updatedItem = new CheckLineDto(_.assign({}, self.itemDetail[detailIndex], values));
                let lookupInfo = self.getCheckLineLookup(updatedItem.lineNumberStr);
                if (lookupInfo) {
                    updatedItem.lineNumberStr = lookupInfo.lineNumber;
                    updatedItem.description = _.parseBool(lookupInfo?.isSystem) ? lookupInfo.lineDescription : updatedItem.description;
                }
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());
                if (_.size(changes) == 0) return Promise.resolve();
                return self.saveCheckLine(updatedItem, changes)
                    .then(result => {
                        let checkLines = _.clone(self.itemDetail);
                        _.assign(checkLines[detailIndex], new CheckLineDto(result.checkLines[0]));
                        let check = new CheckShortDto(result.checks[0]);
                        self.ledgerSummary = result.orderSummary;
                        //only set the amount in case they haven't saved changes to the check
                        self.item.hasValidAccountCode = self.originalItem.hasValidAccountCode = check.hasValidAccountCode;
                        self.item.amount = self.originalItem.amount = check.amount;
                        self.item.description = self.originalItem.description = check.description;
                        self.itemDetail = self.originalDetail = checkLines;
                        self.detailUpdates = true;
                        self.$toast.success({ message: `${self.detailTypeName} ${updatedItem.description || '(Blank)'} was saved.` });
                    }).catch(error => {
                        self.$toast.error({ message: `Error saving ${self.detailTypeName}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onEditorPreparing(e){
                if(e.parentType !== "dataRow") return;
                if(e.dataField === "accountingCodeID") {e.editorOptions.disabled = !this.allowEditAccountCodePermission(e.row.data); return;}
                e.editorOptions.disabled = this.readOnly;
            },

            refresh() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            saveGridChanges(){
                const self = this;
                let promises = [];
                if(!_.isEmpty(self.gridInstance) && self.gridInstance.hasEditData()) promises.push(self.gridInstance.saveEditData());
                return Promise.all(promises);
            },

            save(){
                const self = this;
                if(self.readOnly) return;
                self.errorMessage = "";
                self.v$.$touch();
                if (self.v$.$error) { return Promise.reject({errorMessage: 'Please provide all required fields.'}); }
                let gridChanges = self.gridHasChanges();
                let changes = self.getAuditChanges(self.originalItem, self.item, ["gfNo", "amount"]);
                if (changes.length == 0 && !gridChanges) {
                    if (!self.detailUpdates) self.$toast.info({ message: `No Changes Detected` });
                    return Promise.resolve({checks: [self.item], checkLines: self.itemDetail, orderSummary: self.ledgerSummary});
                }
                return self.saveGridChanges()
                    .then(() => {
                        let gridChanges = self.gridHasChanges();
                        //if there were changes to the grid that didn't save due to validation error(s), then reject
                        if (gridChanges) return Promise.reject({errorMessage: `Please correct ${self.detailTypeName} error.`});
                        let apiPromise = self.$api.CheckWritingApi.saveCheck(self.item, changes);
                        return self.$rqBusy.wait(apiPromise);
                    });
            },

            saveCheckLine(item, changes){
                const self = this;
                self.errorMessage = "";
                if(changes.length === 0) {
                    return Promise.resolve(item);
                }
                if (!self.isUniqueLine(item)) {
                    self.errorMessage = "That Line# already exists.";
                    return Promise.reject(self.errorMessage);
                }

                let apiPromise = self.$api.CheckWritingApi.saveCheckLine(self.check.ordersID, item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise);
            },

            showCompanyDialog (item) {
                const self = this;
                let original = _.clone(item);
                let onOk = function (e) {
                    self.errorMessage = "";
                    let company = _.get(e, "originalEvent.data", e.data);
                    if (!company)
                        return false;
                    self.item.payeeCompanyID = company.companyID;
                    self.item.payee = company.companyName;
                    self.item.buyerSellerID = null;
                    return true;
                };

                self.$dialog.open({
                    title: "Select Company or Contact",
                    height: "90%",
                    width: "85%",
                    component: CompanyContactLookup,
                    props: {defaultSelectedCompanyId: item.payeeCompanyID},
                    closeOnEsc: true,
                    onOk: onOk,
                });
            },

            showFileContactsDialog(item) {
                const self = this;
                let onOk = (e) => {
                    let contact = e.component.selectedContact;
                    self.item.buyerSellerID = contact.buyerSellerID;
                    self.item.payeeCompanyID = contact.companyID;
                    self.item.payee = contact.name;
                    let hasForwarding = _.parseBool(contact.hasForwardingAddress);
                    if (hasForwarding) {
                        let onYes = (e) => {
                            self.item.useForwardingAddress = true;
                        };
                        let onNo = (e) => {
                            self.item.useForwardingAddress = false;
                        };
                        self.$dialog.confirm("Address Selection", "Use forwarding address?", onYes, onNo, { cancelTitle: 'No', okTitle: 'Yes'});
                    }
                };
                self.$dialog.open({
                    title: "Select File Contact",
                    height: "700",
                    width: "1000",
                    resizable: true,
                    adaptive: false,
                    closeOnEsc: true,
                    component: FileContactSelection,
                    onOk: onOk,
                    props: {
                        ordersID: item.ordersID
                    },
                });
            },

            showManualPayeeDialog(item) {
                const self = this;
                let original = _.clone(item);
                let onOk = (e) => {
                    if(_.isNullOrEmpty(e.component.item.payee)){
                        return false;
                    }
                    _.assign(self.item, e.component.item);
                    self.item.buyerSellerID = null;
                    self.item.payeeCompanyID = null;
                    e.component.validate();
                    return true;
                };
                self.$dialog.open({
                    title: "Manual Payee",
                    height: "400",
                    width: "900",
                    resizable: true,
                    adaptive: false,
                    closeOnEsc: true,
                    component: CheckWritingManualPayee,
                    onOk: onOk,
                    props: {
                        item: original
                    },
                });
            },

            onPayeeItemClick({ item }) {
                const self = this;
                switch(item?.name) {
                    case "buyer-seller":
                        self.setBuyerSellerPayee(item?.data);
                        break;
                    case "contact":
                        self.showFileContactsDialog(self.item);
                        break;
                    case "company":
                        self.showCompanyDialog(self.item);
                        break;
                    case "manual":
                        self.showManualPayeeDialog(self.item);
                        break;
                }
            }
        }
    }
</script>
<style lang="scss">
.rq-cw-modal-datagrid.grid-container {
    .dx-grid-container { height: calc(100vh - 600px) !important; }
}
</style>