<template>
    <div class="content-wrapper">
        <div class="row p-2">
            <div :class="{ 'col col-12 form-group form-required':true, 'has-error':v$.quantity.$error }">
                <label>Choose between 1 and 25 clauses to add:</label>
                <rq-input-number
                    ref="txt_multiple_clauses"
                    automation_id="txt_multiple_clauses"
                    formatType="number"
                    defaultValue="1"
                    decimals="0"
                    :commas="false"
                    :minValue="1"
                    :maxValue="25"
                    input-group
                    no-prefix
                    v-model="quantity"
                    value-event="input"
                />
                <rq-validation-feedback
                    :messages="{
                        'Quantity is required': v$.quantity.required.$invalid,
                        'Quantity must be between 1 and 25': v$.quantity.inRange.$invalid,
                    }"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import { useVuelidate } from "@vuelidate/core";
    import { required } from '@vuelidate/validators';
    export default {
        name: "AssignedLanguagesMultipleDialog",
        props: {
        },
        setup: () => ({ v$: useVuelidate() }),
        data () {
            return {
                quantity: 1
            };
        },

        created() {
            this.quantity
        },
        mounted() {
            _.delay(() => {
                _.invoke(this, "$refs.txt_multiple_clauses.instance.select");
            }, 250);
        },
        watch: {
            "v$.$error":{
                handler: function(newValue, oldValue) {
                    this.$emit(`${newValue ? "disable" : "enable"}-ok`);
                },
                deep: true,
                immediate: true
            },
        },
        validations() {
            var result = {
                quantity: {
                    required,
                    inRange(val) {
                        return val > 0 && val < 26;
                    },
                },
            }
            return result;
        },
        computed: {
        },
    }
</script>
