import { OrderSigningRoleType } from "./enum";
export class OrderSigning {
    constructor(options) {
        options = options || {};
        this.orderSigningID = _.parseNumber(options.orderSigningID, 0);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.signingDate = options.signingDate || null;
        this.signingTypeID = _.parseNumber(options.signingTypeID, null);
        this.signingStatusID = _.parseNumber(options.signingStatusID, null);
        this.duration = _.parseNumber(options.duration, null);
        this.description = options.description || null;
        this.address1 = options.address1 || null;
        this.address2 = options.address2 || null;
        this.city = options.city || null;
        this.state = options.state || null;
        this.zip = options.zip || null;
        this.orderSigningRoles = _.map(options.orderSigningRoles, r => new OrderSigningRole(r));
        this.deleteOrderSigningRoles = [];
        this.orderSigningFileScanDocuments = _.map(options.orderSigningFileScanDocuments, r => new OrderSigningFileScanDocument(r));
        this.deleteOrderSigningFileScanDocuments = [];
    }
    
    get addressInvalidAttendees() {
        return _.filter(this.orderSigningRoles, r => r.addressInvalid);
    }

    get attendeeContactInfoValid() { 
        return _.every(this.orderSigningRoles, r => !r.contactInfoInvalid);
    }

    get attendeeAddressInfoValid(){
        return _.every(this.orderSigningRoles, r => !r.addressInvalid);
    }
    get invalidAddressRoleTypes(){
        var invalidAttendees = this.addressInvalidAttendees;
        var roleTypes = _.orderBy(_.uniqBy(_.map(invalidAttendees, a => a.role)));
        return roleTypes.join(", ");
    }
}

export class OrderSigningRole {
    constructor(options) {
        options = options || {};
        this.orderSigningRolesID = _.parseNumber(options.orderSigningRolesID, 0);
        this.orderSigningID = _.parseNumber(options.orderSigningID, 0);
        this.rolesID = _.parseNumber(options.rolesID, 0);
        this.signingRoleTypeID = _.parseNumber(options.signingRoleTypeID, null);
        this.buyerSellerType = _.parseNumber(options.buyerSellerType, null);
        this.role = options.role || null;
        this.contact = options.contact || null;
        this.email = options.email || null;
        this.phone = options.phone || null;
        this.address1 = options.address1 || null;
        this.city = options.city || null;
        this.zip = options.zip || null;
        this.state = options.state || null;
        this.addressRequiredRoleTypes = [OrderSigningRoleType.Cosigner, OrderSigningRoleType.TitleHolder, OrderSigningRoleType.Witness];
    }

    get contactInfoInvalid(){
        return _.isNil(this.contact) || _.isEmpty(this.contact) || _.isNil(this.email) || _.isEmpty(this.email);
    }

    get addressInvalid(){
        return this.addressRequired && (_.isEmpty(this.address1) || _.isNil(this.address1) || _.isEmpty(this.city) || _.isNil(this.city) || _.isEmpty(this.state) || _.isNil(this.state) || _.isEmpty(this.zip) || _.isNil(this.zip) || (this.zip?.length !== 5 && this.zip?.length !== 10));
    }

    get addressRequired(){
        return !_.isNil(this.buyerSellerType) || (!_.isNil(this.signingRoleTypeID) && _.some(this.addressRequiredRoleTypes, t => t === this.signingRoleTypeID))
    }
}

export class OrderSigningFileScanDocument {
    constructor(options) {
        options = options || {};
        this.orderSigningFileScanDocumentsID = _.parseNumber(options.orderSigningFileScanDocumentsID, 0);
        this.orderSigningID = _.parseNumber(options.orderSigningID, 0);
        this.fileScanDocumentID = _.parseNumber(options.fileScanDocumentID, 0);
        this.description = options.description || null;
        this.orderSigningDocumentStatusID = _.parseNumber(options.orderSigningDocumentStatusID, 1);
    }
}
